import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function TraceAccordian({ responseData }) {
  return (
    <div className='mt-4'>
      {responseData.map((accordianElement) => {
        return (
          <Accordion className='!overflow-hidden'>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>{accordianElement?.log_data?.log}</Typography>
            </AccordionSummary>
            <AccordionDetails className='!bg-purple-200'>
              <Typography>{JSON.stringify(accordianElement?.log_data)}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
