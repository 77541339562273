// src/components/PieChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import request from 'superagent';
import keycloak from '../../../Keycloak';

function generateColorHexArray(length) {
  const colorHexArray = [];
  for (let i = 0; i < length; i++) {
    // Generate a random hex color code
    const color =
      '#' +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0');
    colorHexArray.push(color);
  }
  return colorHexArray;
}

const DurationPieChart = ({ chartData, type = 'responseTime', setGridData }) => {
  console.log(chartData, Object.values(chartData?.data?.[0]), 'ttttttttttt');
  const values = Object.values(chartData.data?.[0]);
  const data = {
    labels: Object.keys(chartData.data?.[0]),
    datasets: [
      {
        data: values,
        backgroundColor: generateColorHexArray(values?.length),
        hoverBackgroundColor: generateColorHexArray(values?.length)
      }
    ],
    hoverOffset: 7
  };

  const options = {
    onClick: async (event, element) => {
      if (type == 'responseTime') {
        const chartElementIndex = element?.[0]?.index;
        let obj = {};
        switch (chartElementIndex) {
          case 0:
            obj = {
              response_time1: 0,
              response_time2: 1000
            };
            break;
          case 1:
            obj = {
              response_time1: 1000,
              response_time2: 5000
            };
            break;
          case 2:
            obj = {
              response_time1: 5000,
              response_time2: 10000
            };
            break;
          case 3:
            obj = {
              response_time1: 10000,
              response_time2: 0
            };
            break;
          default:
          // code block
        }

        const response = await request
          .post(
            `${process.env.REACT_APP_GENRIC_API}/generic/feature/chart/featureListByResponseTime`
          )
          .send(obj)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        const gridData = response?.body?.response;
        setGridData(gridData);
        // console.log(gridData, 'eeeeeeeeeee');
      } else if (type == 'response_codes') {
        const chartElementIndex = element[0].index;
        const label = Object.keys(chartData.data?.[0])[chartElementIndex];
        let obj = {
          response_code: Number(label.split('_')[1])
        };

        const response = await request
          .post(
            `${process.env.REACT_APP_GENRIC_API}/generic/feature/chart/featureListByResponseCode`
          )
          .send(obj)
          .set('Authorization', `Bearer ${keycloak.token()}`);

        const gridData = response?.body?.response;
        setGridData(gridData);
      }
    }
  };

  return (
    <div className="w-[30%] m-auto">
      <Pie data={data} options={options} />
    </div>
  );
};

export default DurationPieChart;
