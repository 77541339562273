import React, { useEffect, useState } from 'react';
import SideBar from '../ApplicationV2/component/SideBar';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  Container
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import keycloak from '../../Keycloak';

export default function BulkUploadList() {
  const { app_code = '' } = useParams();
  const [uploadList, setUploadList] = useState();

  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(()=>{
    getList()
  },[])

  const [bulkProcesses,setBulkProcess]=useState([])

  function getList() {
    axios.get('https://api.config.innovation.realware.app/bulkProcess/getAllBulkProcessArray', {
      params: {
        appCode: app_code // This automatically appends ?appCode=app_code to the URL
      },
      headers: {
        'Authorization': `Bearer ${keycloak.token()}` // Correctly setting the Authorization header
      }
    })
    .then((res) => {console.log(res);setBulkProcess(res.data.primary)})
    .catch((err) => console.log(err));
  }

  // Sample data for the table
  // const bulkProcesses = [
  //   {
  //     bulkProcessId: 1,
  //     bulkProcessCode: 'BP001',
  //     bulkProcessName: 'Process One',
  //     description: 'Description of process one',
  //     dataObjectName: 'Data Object One',
  //     mapping: 'Map1',
  //     status: 'Active',
  //   },
  //   {
  //     bulkProcessId: 2,
  //     bulkProcessCode: 'BP002',
  //     bulkProcessName: 'Process Two',
  //     description: 'Description of process two',
  //     dataObjectName: 'Data Object Two',
  //     mapping: 'Map2',
  //     status: 'Inactive',
  //   },
  //   // Add more items as needed
  // ];

  return (
    <Box display="flex">
      <SideBar page={'bulkProcessing'} />
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
          <Typography variant="h4">Bulk Uploads</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/apps/excelMapping/${app_code}`)}>
            New Upload
          </Button>
        </Box>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                
                <TableCell>Bulk Process Code</TableCell>
                <TableCell>Bulk Process Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Data Object Name</TableCell>
                <TableCell>Mapping</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkProcesses.map((process) => (
                <TableRow key={process.bulkProcessId}>
                 
                  <TableCell>{process.bulkProcessCode}</TableCell>
                  <TableCell>{process.bulkProcessName}</TableCell>
                  <TableCell>{process.description}</TableCell>
                  <TableCell>{process.dataObjectName}</TableCell>
                  <TableCell>
                    <Button onClick={()=>alert(JSON.stringify(process.mapping, null, 4))}>
                      Mapping 
                    </Button>
                    </TableCell>
                  <TableCell>{"Active"}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={()=>alert(JSON.stringify(process, null, 4))}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>
    </Box>
  );
}
