import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';
import Joi from 'joi';
import { isEmpty, noop } from 'lodash';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import request from 'superagent';
import keycloak from '../../../Keycloak';
import infoIcon from '../../../public/icons/info.png';
import connectionFrameworkBasicInfoSchema from '../../../schema/form_schema/connectionFrameworkBasicInfoSchema';
import connectionFrameworkNotifyInfoSchema from '../../../schema/form_schema/connectionFrameworkNotifyInfoSchema';
import connectionFrameworkTypeInfoSchema from '../../../schema/form_schema/connectionFrameworkTypeInfoSchema';
import SideBar from '../../ApplicationV2/component/SideBar';
import ConformationContainer from '../../common/conformationModal/containers';
import FormV2Container from '../../common/formV2/container/FormV2Container';
import ModalKeys from '../../modals/genericModal/constants/GenericModalKeys';
import { genericModalStateUpdate } from '../../modals/slices/genericModalSlice';
import Error from '../../notify/ErrorNotify';
import Success from '../../notify/Success';
import LadderStepForm from '../../stepForms/ladderStepForm/components/LadderStepForm';
import MongoDBQueryResult from './MongoDBQueryResult';
import GenericModalContainer from '../../modals/genericModal/container';
// import Drop from './OcrFileDrop';
import checkIPValidation from '../../common/formV2/utils/checkIPValidation';
import DBQueryResult from './DBQueryResult';
import OcrFileDrop from './OcrFileDrop';

const ConnectionFrameworkForm = () => {
  const { app_code = '', action = '', actionId = '' } = useParams();
  const navigate = useNavigate();
  const [actionData, setActionData] = useState(null);
  const [ocrname,setOcrName]=useState()
  const [ocrIdOptions, setOcrIdOptions] = useState([]);
  function transformData(inputData) {
    let outputData = [];

    inputData.forEach((item) => {
      let outputItem = { name: item['OcrName'], value:JSON.stringify({
        ocrId: item['OcrId'],
        ocrName: item['OcrName']
      }) };
      outputData.push(outputItem);
    });

    return outputData;
  }

  const ocrIdApi = async () => {
    const ocrIdResponse = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/getOcrIds`)
      .send({ appCode: app_code })
      .set('Authorization', `Bearer ${keycloak.token()}`);
    if (ocrIdResponse?.body?.Result) {
      setOcrIdOptions(transformData(ocrIdResponse?.body?.Result));
      console.log("Data for 58..",transformData(ocrIdResponse?.body?.Result),)

    }
  };

  useEffect(() => {
    const response = request
      .get(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/getEditData`)
      .query({ connectorId: actionId })
      .set('Authorization', `Bearer ${keycloak.token()}`);

    response.then((data) => {
      if (data.body.Status == 'Success') {
        setActionData(data.body.Result[0]);
        setOcrName(data.body.Result[0]?.ocr?.ocrName || '')
        console.log("data is action data",data.body.Result[0]?.ocr?.ocrName)

      }
    });
    ocrIdApi();
  }, []);

  const { name, description, org_id, type, pollingInterval } = actionData || {};
  const { notifyData: notifyFormData = {} } = actionData || {};

  const basicInfoData = { name, description, org_id, type, pollingInterval };
  const { metaData: formData = {} } = actionData || {};

  const [secretKey, setSecretKey] = useState('');
  const [webhookId, setWebhookId] = useState('');
  const [isMappingChecked, setIsMappingChecked] = useState(false);
  const [isSecretKeyCopied, setIsSecretKeyCopied] = useState(false);
  const [partyIds, setPartyIds] = useState([]);
  const [queryResult, setQueryResult] = useState(null);
  const [isTestQuery, setIsTestQuery] = useState(false);
  const [responseObject, setResponseObject] = useState([]);
  const [dataObject, setDataObject] = useState([]);
  const [IMAPServer, setIMAPServer] = useState('');
  const [OCRData, setOCRData] = useState(null);
  const [FileData, setFileData] = useState(null);
  const notifyRef = useRef();

  const [webhookUrl, setWebHookurl] = useState(
    `${process.env.REACT_APP_CONFIG_API}/type/connectors/webhook`
  );

  const [isTestCheck, isTestCheckReducer] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'Mail':
        case 'db':
        case 'SFTP':
        case 'webhook':
        case 'http':
        case 'ocr': {
          return {
            ...state,
            [action.type]: true
          };
        }
        case 'initialize': {
          return {
            Mail: false,
            db: false,
            SFTP: false,
            webhook: true,
            ocr: false,
            http: false
          };
        }
      }
    },
    {
      Mail: false,
      db: false,
      SFTP: false,
      webhook: true,
      http: false
    }
  );

  const onMenuItemClick = ({ title }) => {
    switch (title) {
      case 'Connections': {
        navigate(`/apps/${app_code}/connections`);
        break;
      }
      case 'Variable Management': {
        navigate(`/apps/variables/${app_code}`);
        break;
      }
      case 'Connection Logs': {
        navigate(`/apps/${app_code}/connectionLogs`);
        break;
      }
    }
  };

  const dispatch = useDispatch();

  const [currentNotifyType, setCurrentNotifyType] = useState('Kafka');
  const currentConnectionForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework']) || {};
  const currentConnectionType = currentConnectionForm.type;
  const currentMailTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-Mail']) || {};
  const currentHttpTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-http']) || {};
  const currentOcrTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-ocr']) || {};
  const currentSFTPTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-SFTP']) || {};
  const currentDBTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-DB']) || {};
  const currentWebHooksTypeForm =
    useSelector((store) => store.store.form?.['formData']?.['Connection Framework-WebHooks']) || {};

  const currentAllKafkaNotifyForm =
    useSelector((store) => store.store.form?.['formData']?.['Notify-Kafka']) || {};

  const authType = currentHttpTypeForm?.authType;

  const currentKafkaNotifyForm = {
    ...currentAllKafkaNotifyForm,
    ...currentAllKafkaNotifyForm['Transformation-0'],
    ...currentAllKafkaNotifyForm['Optical Character Recognition-0']
  };

  const currentAllWebHooksNotifyForm =
    useSelector((store) => store.store.form?.['formData']?.['Notify-WebHooks']) || {};

  const currentWebHooksNotifyForm = {
    ...currentAllWebHooksNotifyForm,
    ...currentAllWebHooksNotifyForm['Transformation-0'],
    ...currentAllWebHooksNotifyForm['Optical Character Recognition-0']
  };

  const [isTransform, setIsTransform] = useState(false);

  const [isOCR, setIsOCR] = useState(false);

  // 'uniqueField'

  const isPollingNeeded = !(currentConnectionType == 'webhook' || currentConnectionType == 'ocr');

  const typeFieldValidation = {
    db: ['type', 'host', 'port', 'username', 'password', 'database','collection'],
    webhook: ['WebHookUrl', 'secretKey', 'hashingAlgorithm'],
    SFTP: ['host', 'port', 'username', 'password', 'remotePath'],
    Mail: ['user', 'appPassword', 'host'],
    http: ['method', 'url', 'authType'],
    ocr: ['ocrType']
  };

  const typeData = {
    db: currentDBTypeForm,
    webhook: currentWebHooksTypeForm,
    SFTP: currentSFTPTypeForm,
    Mail: currentMailTypeForm,
    http: currentHttpTypeForm,
    ocr: currentOcrTypeForm
  };

  function validatePort(value) {
    return Joi.alternatives()
      .try(
        Joi.number().required(), // Validates if the text is a valid email
        Joi.string()
          .regex(/^[$][a-zA-Z_][a-zA-Z0-9_]*$/)
          .required() // Validates if the text starts with "$"
      )
      .validate(value).error;
  }

  const customCheck = ({ type, element, value }) => {
    switch (type) {
      case 'SFTP': {
        if (element == 'host') {
          return !checkIPValidation(value);
        } else if (element == 'port') {
          // console.log('here');
          return !Joi.alternatives()
            .try(
              Joi.number().required(), // Validates if the text is a valid number
              Joi.string()
                .regex(/^[$][a-zA-Z_][a-zA-Z0-9_]*$/)
                .required() // Validates if the text starts with "$"
            )
            .validate(value).error;
        } else {
          return true;
        }
      }
      case 'db': {
        if (element == 'host') {
          return !checkIPValidation(value);
        } else if (element == 'port') {
          return !validatePort(value);
        } else {
          return true;
        }
      }
      default: {
        return true;
      }
    }
  };

  var isTypeDisabled = useMemo(() => {
    const data = typeData[currentConnectionType];

    const updatedTypeValidation = typeFieldValidation[currentConnectionType];

    if (currentConnectionType == 'db' && currentDBTypeForm.type == 'mongo') {
      updatedTypeValidation.push('collection');
    }

    return !updatedTypeValidation?.every((element) => {
      console.log(element, '66666666666');
      if (data[element] == '') {
        return false;
      } else {
        return customCheck({ type: currentConnectionType, element: element, value: data[element] });
      }
    });
  }, [JSON.stringify(typeData[currentConnectionType])]);

  const isKafka = notifyFormData.type == 'webhook' ? false : true;

  function typeSchemaReducer(state, action) {
    switch (action.type) {
      case 'type/Mail': {
        const newState = {
          title: 'Connection Framework-Mail',
          hideTitle: true,
          formSchema: [
            {
              name: 'user',
              label: 'Email *',
              type: 'text',
              required: true,
              defaultValue: formData?.user,
              disabled: !!actionId
            },
            {
              name: 'Resolve IMAP Server',
              label: 'Resolve IMAP Server *',
              type: 'button',
              hideMe: !!actionId,
              classname: 'h-[40px] relative top-[46px]',
              disabled: !!Joi.alternatives()
                .try(
                  Joi.string()
                    .email({ tlds: { allow: false } })
                    .required(), // Validates if the text is a valid email
                  Joi.string()
                    .regex(/^[$][a-zA-Z_][a-zA-Z0-9_]*$/)
                    .required() // Validates if the text starts with "$"
                )
                .validate(currentMailTypeForm.user).error,
              onButtonClick: async function () {
                const response = await request
                  .post(
                    `${process.env.REACT_APP_CONFIG_API}/type/connectors/mail/resolveMailServer`
                  )
                  .query({ appCode: app_code })
                  .send({
                    mail: currentMailTypeForm.user
                  })
                  .set('Authorization', `Bearer ${keycloak.token()}`);

                if (response.body.status == 'Failed') {
                  toast.custom((t) => <Error t={t} message={response.body.message} />, {
                    duration: 4000
                  });
                } else {
                  toast.custom((t) => <Success t={t} message={'Resolved IMAP Successfully'} />, {
                    duration: 4000
                  });
                  setIMAPServer(response.body.message);
                }
              }
            },
            {
              name: 'appPassword',
              label: 'App Password *',
              type: 'text',
              required: true,
              defaultValue: formData?.appPassword,
              disabled: !!actionId
            },
            {
              name: 'host',
              label: 'IMAP Server *',
              type: 'text',
              required: true,
              disabled: !!IMAPServer || !!actionId,
              defaultValue: IMAPServer || formData?.host
            },
            {
              name: 'Test Connection',
              label: 'Test Connection',
              type: 'button',
              hideMe: !!actionId,
              classname: 'h-[40px] relative top-[46px]',
              disabled: isTypeDisabled,
              onButtonClick: async () => {
                const data = typeData[currentConnectionType];

                const payload = typeFieldValidation[currentConnectionType]?.reduce(
                  (acc, element) => {
                    return {
                      ...acc,
                      [element]: data[element]
                    };
                  },
                  {}
                );

                const response = await request
                  .post(
                    `${process.env.REACT_APP_CONFIG_API}/type/connectors/mail/checkMailConnection`
                  )
                  .query({ appCode: app_code })
                  .send(payload)
                  .set('Authorization', `Bearer ${keycloak.token()}`);

                const isConnected = response.body.isConnected;
                const Message = response.body.Message;

                if (isConnected) {
                  isTestCheckReducer({ type: currentConnectionType });
                  toast.custom((t) => <Success t={t} message={'Authentication Successfully'} />, {
                    duration: 4000
                  });
                } else {
                  toast.custom(
                    (t) => (
                      <Error t={t} message={response.body.message || 'Authenticated Failed'} />
                    ),
                    {
                      duration: 4000
                    }
                  );
                }
              }
            }
          ]
        };
        return newState;
      }
      case 'type/SFTP': {
        const newState = {
          title: 'Connection Framework-SFTP',
          hideTitle: true,
          formSchema: [
            {
              name: 'host',
              label: 'Host *',
              type: 'text',
              required: true,
              defaultValue: formData.host,
              disabled: !!actionId
            },
            {
              name: 'port',
              label: 'Port *',
              type: 'text',
              required: true,
              defaultValue: formData.port,
              disabled: !!actionId
            },
            {
              name: 'username',
              label: 'User Name *',
              type: 'text',
              required: true,
              defaultValue: formData.username,
              disabled: !!actionId
            },
            {
              name: 'password',
              label: 'Password *',
              type: 'text',
              required: true,
              defaultValue: formData.password,
              disabled: !!actionId
            },
            {
              name: 'remotePath',
              label: 'Remote Path *',
              type: 'text',
              required: true,
              defaultValue: formData.remotePath,
              disabled: !!actionId
            },
            {
              name: 'Check Connection',
              label: 'Check Connection',
              type: 'button',
              hideMe: !!actionId,
              classname: 'h-[40px] relative top-[46px]',
              disabled: isTypeDisabled,
              onButtonClick: async () => {
                const typeInformationFields = typeFieldValidation[currentConnectionType];

                const typeInformationData = typeInformationFields.reduce((acc, element) => {
                  return {
                    ...acc,
                    [element]: typeData[currentConnectionType][element] || ''
                  };
                }, {});

                try {
                  const response = await request
                    .post(
                      `${process.env.REACT_APP_CONFIG_API}/type/connectors/sftp/checksftpConnection`
                    )
                    .query({ appCode: app_code })
                    .set('Authorization', `Bearer ${keycloak.token()}`)
                    .send(typeInformationData);

                  if (response.body.status == 'Success') {
                    isTestCheckReducer({ type: currentConnectionType });
                    toast.custom((t) => <Success t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                  } else {
                    toast.custom((t) => <Error t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                  }
                } catch (err) {
                  toast.custom(
                    (t) => (
                      <Error
                        t={t}
                        message={response.body.message || 'Error while checking connection'}
                      />
                    ),
                    {
                      duration: 4000
                    }
                  );
                }
              }
            }
          ]
        };
        return newState;
      }
      case 'type/webhook': {
        const newState = {
          title: 'Connection Framework-WebHooks',
          hideTitle: true,
          formSchema: [
            {
              name: 'WebHookUrl',
              label: 'WebHook URL *',
              type: 'text',
              required: true,
              defaultValue: webhookUrl,
              disabled: true,
              isCopyButton: true,
              copyButtonCallback: async () => {
                await navigator.clipboard.writeText(webhookUrl);
                toast.custom((t) => <Success t={t} message={'Url copied Successfully'} />, {
                  duration: 4000
                });
              }
            },
            {
              name: 'secretKey',
              label: 'Secret Key *',
              type: 'text',
              defaultValue: secretKey,
              required: true,
              disabled: true,
              hideMe: actionId,
              isCopyButton: !!secretKey,
              copyButtonCallback: async () => {
                await navigator.clipboard.writeText(secretKey);
                toast.custom((t) => <Success t={t} message={'Secret Key copied Successfully'} />, {
                  duration: 4000
                });
                setIsSecretKeyCopied(true);
              }
            },
            {
              name: 'generateSecretKey',
              label: 'Generate Secret Key',
              type: 'button',
              hideMe: actionId,
              classname: 'h-[40px] relative top-[46px]',
              disabled: secretKey,
              onButtonClick: async () => {
                const secKey = await request
                  .post(
                    `${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/generateSecretKey`
                  )
                  .send({})
                  .set('Authorization', `Bearer ${keycloak.token()}`);
                setSecretKey(secKey?.body?.Key);
                toast.custom((t) => <Success t={t} message={'Secret Key Generated'} />, {
                  duration: 4000
                });
              }
            },
            {
              name: 'hashingAlgorithm',
              label: 'Hashing Algo *',
              type: 'dropdown',
              disabled: !!actionId,
              defaultValue: formData.hashingAlgorithm,
              options: [
                { name: 'MD5', value: 'MD5' },
                { name: 'SHA1', value: 'SHA1' }
              ],
              required: true
            },
            {
              name: 'signatureHeader',
              label: 'Signature Header *',
              type: 'text',
              disabled: true,
              defaultValue: 'X-REALWARE-SIGNATURE',
              required: true
            }
          ]
        };
        return newState;
      }
      case 'type/db': {
        setQueryResult(null);
        const newState = {
          title: 'Connection Framework-DB',
          hideTitle: true,
          formSchema: [
            {
              name: 'type',
              label: 'DB Type *',
              type: 'dropdown',
              defaultValue: formData.type,
              disabled: !!actionId,
              options: [
                {
                  name: 'MongoDB',
                  value: 'mongo'
                },
                {
                  name: 'MySQL',
                  value: 'mysql'
                },
                {
                  name: 'MSSQL',
                  value: 'mssql'
                },
                {
                  name: 'PostgreSQL',
                  value: 'PostgreSQL'
                }
              ],
              required: true
            },
            {
              name: 'host',
              label: 'Host *',
              type: 'text',
              defaultValue: formData.host,
              required: true,
              specialValidate: true,
              validateFunc: checkIPValidation,
              disabled: !!actionId
            },
            {
              name: 'port',
              label: 'Port *',
              type: 'text',
              defaultValue: formData.port,
              validateFunc: validatePort,
              required: true,
              disabled: !!actionId
            },
            {
              name: 'username',
              label: 'User Name *',
              type: 'text',
              required: true,
              defaultValue: formData.username,
              disabled: !!actionId
            },
            {
              name: 'password',
              label: 'Password *',
              type: 'text',
              required: true,
              defaultValue: formData.password,
              disabled: !!actionId
            },
            {
              name: 'database',
              label: 'DB Name *',
              type: 'text',
              required: true,
              defaultValue: formData.database,
              disabled: !!actionId
            },
            currentDBTypeForm.type == 'mongo' ||  currentDBTypeForm.type == 'mysql'
              ? {
                  name: 'collection',
                  label: 'Collection Name *',
                  type: 'text',
                  required: true,
                  defaultValue: formData.collection,
                  disabled: !!actionId
                }
              : {},
            // {
            //   name: 'uniqueField',
            //   label: 'Unique Field',
            //   type: 'text',
            //   required: true
            // },
            {
              name: 'checkConnection',
              label: 'Check Connection',
              type: 'button',
              hideMe: !!actionId,
              classname: 'h-[40px] !mt-[32px]',
              disabled: isTypeDisabled,
              onButtonClick: async function () {
                const updatedTypeValidation = typeFieldValidation[currentConnectionType];

                if (currentConnectionType == 'db' && currentDBTypeForm.type == 'mongo') {
                  updatedTypeValidation.push('collection');
                }

                const typeInformationData = updatedTypeValidation.reduce((acc, element) => {
                  return {
                    ...acc,
                    [element]: typeData[currentConnectionType][element] || ''
                  };
                }, {});

                try {
                  const response = await request
                    .post(
                      `${process.env.REACT_APP_CONFIG_API}/type/connectors/database/checkDbConnection`
                    )
                    .query({ appCode: app_code })
                    .set('Authorization', `Bearer ${keycloak.token()}`)
                    .send(typeInformationData);

                  if (response.body.status == 'Success') {
                    toast.custom((t) => <Success t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                    setIsTestQuery(true);
                    isTestCheckReducer({ type: currentConnectionType });
                  } else {
                    toast.custom((t) => <Error t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                  }
                } catch (err) {
                  toast.custom(
                    (t) => (
                      <Error
                        t={t}
                        message={response.body.message || 'Error while checking connection'}
                      />
                    ),
                    {
                      duration: 4000
                    }
                  );
                }
              }
            },
            {
              name: 'query',
              label: 'Query *',
              type: 'textV2',
              editor: true,
              required: true,
              defaultLanguage: 'sql',
              hideMe: !!actionId
            },
            {
              name: 'testQuery',
              label: 'Test Query',
              type: 'button',
              hideMe: !!actionId,
              classname: 'h-[40px] !mt-[32px]',
              disabled: !isTestQuery,
              onButtonClick: async () => {
                const updatedTypeValidation = typeFieldValidation[currentConnectionType].concat([
                  'query'
                ]);

                if (currentConnectionType == 'db' && currentDBTypeForm.type == 'mongo') {
                  updatedTypeValidation.push('collection');
                }

                const typeInformationData = updatedTypeValidation.reduce((acc, element) => {
                  return {
                    ...acc,
                    [element]: typeData[currentConnectionType][element] || ''
                  };
                }, {});

                try {
                  const response = await request
                    .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/database/testQuery`)
                    .query({ appCode: app_code })
                    .set('Authorization', `Bearer ${keycloak.token()}`)
                    .send(typeInformationData);

                  if (response.body.status == 'Success') {
                    toast.custom((t) => <Success t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                    setIsTestQuery(true);
                    isTestCheckReducer({ type: currentConnectionType });
                    setQueryResult(response.body.result);
                  } else {
                    toast.custom((t) => <Error t={t} message={response.body.message} />, {
                      duration: 4000
                    });
                  }
                } catch (err) {
                  toast.custom(
                    (t) => (
                      <Error
                        t={t}
                        message={response.body.message || 'Error while checking connection'}
                      />
                    ),
                    {
                      duration: 4000
                    }
                  );
                }
              }
            }
          ]
        };
        return newState;
      }

      case 'type/http': {
        const newState = {
          title: 'Connection Framework-http',
          hideTitle: true,
          formSchema: [
            {
              name: 'method',
              label: 'Select Methods *',
              type: 'dropdown',
              defaultValue: formData.method,
              disabled: !!actionId,
              options: [
                {
                  name: 'GET',
                  value: 'GET'
                },
                {
                  name: 'POST',
                  value: 'POST'
                }
              ],
              required: true
            },
            {
              name: 'url',
              disabled: !!actionId,
              defaultValue: formData.url,
              label: 'URL *',
              type: 'text',
              required: true
            },
            {
              name: 'headers',
              defaultValue: formData.headers,
              disabled: !!actionId,
              label: 'Headers',
              type: 'text',
              required: true
            },
            {
              name: 'authType',
              label: 'Auth Type *',
              defaultValue: formData.authType,
              disabled: !!actionId,
              type: 'dropdown',
              options: [
                {
                  name: 'No Auth',
                  value: 'noAuth'
                },
                {
                  name: 'Basic',
                  value: 'basic'
                },
                { name: 'Bearer', value: 'bearer' }
              ]
            },
            {
              name: 'queryparams',
              defaultValue: formData.queryparams,
              disabled: !!actionId,
              label: 'Query Params',
              type: 'textV2',
              editor: true,
              required: true
            },
            {
              name: 'bodyData',
              defaultValue: formData.bodyData,
              disabled: !!actionId,
              label: 'body',
              type: 'textV2',
              editor: true,
              required: true
            },

            authType in { basic: true, bearer: true }
              ? {
                  name: 'authToken',
                  defaultValue: formData.authToken,
                  disabled: !!actionId,
                  label: 'Auth Code',
                  type: 'text',
                  required: true
                }
              : {},
            {
              name: 'checkConnection',
              label: 'Check Connection',
              hideMe: !!actionId,
              type: 'button',
              classname: 'h-[40px] !mt-[32px]',
              disabled: isTypeDisabled,
              onButtonClick: async () => {
                const typeInformationFields = typeFieldValidation[currentConnectionType].concat([
                  'queryparams',
                  'bodyData'
                ]);

                const typeInformationData = typeInformationFields.reduce((acc, element) => {
                  return {
                    ...acc,
                    [element]: typeData[currentConnectionType][element] || ''
                  };
                }, {});

                delete typeInformationData.authType;

                try {
                  const response = await request
                    .post(
                      `${process.env.REACT_APP_CONFIG_API}/type/connectors/http/checkHttpConnection`
                    )
                    .query({ appCode: app_code })
                    .set('Authorization', `Bearer ${keycloak.token()}`)
                    .send(typeInformationData);

                  if (response.body.Status == 'Success') {
                    toast.custom((t) => <Success t={t} message={'Connection Checked'} />, {
                      duration: 4000
                    });
                    isTestCheckReducer({ type: currentConnectionType });
                  } else {
                    toast.custom(
                      (t) => (
                        <Error
                          t={t}
                          message={response.body.message || 'Error while checking connection'}
                        />
                      ),
                      {
                        duration: 4000
                      }
                    );
                  }
                } catch (err) {
                  toast.custom(
                    (t) => (
                      <Error
                        t={t}
                        message={response.body.message || 'Error while checking connection'}
                      />
                    ),
                    {
                      duration: 4000
                    }
                  );
                }
              }
            }
          ]
        };
        return newState;
      }

      case 'type/ocr': {
        const newState = {
          title: 'Connection Framework-ocr',
          hideTitle: true,
          formSchema: [
            {
              name: 'ocrType',
              label: 'OCR Types *',
              type: 'dropdown',
              defaultValue: notifyData[currentNotifyType].oCRTypes || formData.ocrType || '',
              disabled: !!actionId,
              options: [
                { name: 'INVOICE', value: 'invoice' },
                {
                  name: 'PURCHASE ORDER',
                  value: 'purchaseOrder'
                }
              ]
            },
            {
              name: 'uploadOcr',
              label: 'Upload File',
              hideMe: !!actionId,
              type: 'button',
              classname: 'h-[40px] !mt-[36px]',
              disabled: !currentOcrTypeForm.ocrType,
              onButtonClick: async () => {
                dispatch(
                  genericModalStateUpdate({
                    modalKey: ModalKeys.ocrFileUplaod,
                    isOpen: true
                  })
                );
              }
            },
            {
              name: 'viewocrfile',
              label: 'View File ',
              hideMe: !actionId,
              type: 'button',
              // href:`https://api.config.innovation.realware.app/type/connectors/doc_uploads/ocr_files/${formData.ocrFilePath}`,
              classname: 'h-[40px] !mt-[36px]',
              disabled: !currentOcrTypeForm.ocrType,
              onButtonClick: async () => {
                const a = document.createElement('a');
                // Set the href to the file URL
                a.href = `https://api.config.innovation.realware.app/type/connectors/doc_uploads/ocr_files/${formData.ocrFilePath}`; // Replace with your file URL
                // Set the target attribute to open in a new tab
                a.target = '_blank';
                // Set the download attribute to suggest downloading
                a.download = 'file.pdf'; // Replace with your desired file name
                // Append the anchor to the body (required for Firefox)
                document.body.appendChild(a);
                // Trigger the action by simulating a click on the anchor
                a.click();
                // Remove the anchor from the document
                document.body.removeChild(a);
              }
            }
          ]
        };
        return newState;
      }
    }
  }

  const notifyData = {
    Kafka: currentKafkaNotifyForm,
    WebHooks: currentWebHooksNotifyForm
  };

  notifyRef.current = { ...notifyData };

  const [notifyValidation, setNotifyValidation] = useState({
    Kafka: ['broker', 'topic'],
    WebHooks: ['secretKey', 'hashingAlgorithm', 'signatureHeader']
  });

  const isNotifyDisabled = useMemo(
    () => {
      const data = notifyData[currentNotifyType];

      const updatedValidations = notifyValidation[currentNotifyType];

      if (isOCR) {
        updatedValidations.push(`oCRTypes-${currentNotifyType}`);
      }

      return !notifyValidation[currentNotifyType].every((element) => {
        if (data && data[element] == '') {
          return false;
        } else {
          return true;
        }
      });
    },
    [JSON.stringify(notifyData[currentNotifyType]), JSON.stringify(notifyValidation)],
    isMappingChecked,
    isTransform,
    isOCR
  );

  function updateNotifyValidation() {
    var validationArray;
    if (isTransform) {
      validationArray = notifyValidation[currentNotifyType].concat(
        isTransform
          ? [`ResponseObject-${currentNotifyType}`, `DataObject-${currentNotifyType}`]
          : []
      );
    } else {
      validationArray = notifyValidation[currentNotifyType].filter(
        (ele) =>
          ele != `ResponseObject-${currentNotifyType}` && ele != `DataObject-${currentNotifyType}`
      );
    }

    if (isOCR) {
      validationArray.concat(
        notifyValidation[currentNotifyType].concat(
          isTransform ? [`oCRTypes-${currentNotifyType}`] : []
        )
      );
    } else {
      validationArray.concat(
        notifyValidation[currentNotifyType].filter((ele) => ele != `oCRTypes-${currentNotifyType}`)
      );
    }

    const updatedValidation = { ...notifyValidation, [currentNotifyType]: validationArray };

    setNotifyValidation(updatedValidation);
  }

  const transformationFormData = actionData?.transformation || {};
  console.log('transform formdk,jhsdbvksdl.ksdanvfsa,mg.j ', formData);

  function NotifyReducer(state, action) {
    switch (action.type) {
      case 'notify/Kafka': {
        const result = updateNotifyValidation();
        const newState = {
          title: 'Notify-Kafka',
          hideTitle: true,
          formSchema: [
            {
              name: 'broker',
              label: 'Kafka Broker',
              type: 'text',
              required: true,
              defaultValue: notifyFormData.broker,
              disabled: !!actionId
            },
            {
              name: 'topic',
              label: 'Topic Name',
              type: 'text',
              required: true,
              defaultValue: notifyFormData.topic,
              disabled: !!actionId
            },
            isTransform || !!actionData?.transformation
              ? {
                  name: 'Transformation',
                  type: 'Fields',
                  duplicate: false,
                  varient: 'full',
                  data: {
                    0: [
                      {
                        name: 'ResponseObject-Kafka',
                        label: 'Request Object',
                        type: 'dropdown',
                        options: responseObject,
                        defaultValue: JSON.stringify({
                          code: transformationFormData?.requestObject?.requestObjectCode,
                          dataObjectId: transformationFormData?.requestObject?.requestObjectId
                        }),
                        disabled: !!actionId
                      },
                      {
                        name: 'DataObject-Kafka',
                        label: 'Data Object',
                        type: 'dropdown',
                        options: dataObject,
                        defaultValue: JSON.stringify({
                          code: transformationFormData?.dataObject?.dataObjectCode,
                          dataObjectId: transformationFormData?.dataObject?.dataObjectId
                        }),
                        disabled: !!actionId
                      },
                      {
                        name: 'persist-Kafka',
                        label: 'Persist',
                        type: 'checkbox',
                        classname: '!mt-8',
                        defaultValue: !!transformationFormData?.persistRequired,
                        disabled: !!actionId
                      },
                      {
                        name: 'Check Mapping',
                        label: 'Check Mapping',
                        type: 'button',
                        hideMe: !!actionId,
                        classname: 'h-[40px] !mt-[46px]',
                        disabled: isNotifyDisabled,
                        onButtonClick: async () => {
                          const notifyInformationFields = notifyValidation[currentNotifyType];

                          const notifyInformationData = notifyInformationFields.reduce(
                            (acc, element) => {
                              return {
                                ...acc,
                                [element]: notifyRef.current[currentNotifyType][element] || ''
                              };
                            },
                            {}
                          );
                          const payload = {
                            dataObjectId: JSON.parse(
                              notifyInformationData[`DataObject-${currentNotifyType}`]
                            ).dataObjectId,
                            requestObjectId: JSON.parse(
                              notifyInformationData[`ResponseObject-${currentNotifyType}`]
                            ).dataObjectId,
                            appCode: app_code
                          };
                          try {
                            const response = await request
                              .post(
                                `${process.env.REACT_APP_CONFIG_API}/object-mapping/checkMapping`
                              )
                              .set('Authorization', `Bearer ${keycloak.token()}`)
                              .send(payload);

                            if (response.body.code == 'SUCCESS') {
                              setIsMappingChecked(true);
                              toast.custom(
                                (t) => <Success t={t} message={response.body.message} />,
                                {
                                  duration: 4000
                                }
                              );
                            } else {
                              toast.custom((t) => <Error t={t} message={response.body.message} />, {
                                duration: 4000
                              });
                            }
                          } catch (err) {
                            toast.custom(
                              (t) => (
                                <Error
                                  t={t}
                                  message={
                                    response.body.message || 'Error while checking connection'
                                  }
                                />
                              ),
                              {
                                duration: 4000
                              }
                            );
                          }
                        }
                      }
                    ]
                  }
                }
              : {},
            actionData?.ocr || isOCR
              ? {
                  name: 'Optical Character Recognition',
                  type: 'Fields',
                  duplicate: false,
                  varient: 'full',
                  disabled: !!actionId,
                  data: {
                    0: [
                      {
                        name: 'oCRTypes-Kafka',
                        label: 'OCR Types',
                        type: 'dropdown',
                        defaultValue:

                        JSON.stringify({ocrId: actionData?.ocr?.ocrId, ocrName: actionData?.ocr?.ocrName}),
                        // actionData?.ocr.ocrName,
                          // notifyData[currentNotifyType].oCRTypes || actionData?.ocr?.type || '',
                        disabled: !!actionId,
                        // options: [
                        //   { name: 'INVOICE', value: 'invoice' },
                        //   {
                        //     name: 'PURCHASE ORDER',
                        //     value: 'purchaseOrder'
                        //   }
                        // ]
                        
                        options: ocrIdOptions
                      }
                    ]
                  }
                }
              : {}
          ]
        };
        return newState;
      }

      case 'notify/WebHooks': {
        const result = updateNotifyValidation();
        const newState = {
          title: 'Notify-WebHooks',
          hideTitle: true,
          formSchema: [
            {
              name: 'secretKey',
              label: 'Secret Key',
              type: 'text',
              required: true,
              disabled: !!actionId,
              defaultValue: notifyFormData.secretKey
            },
            {
              name: 'hashingAlgorithm',
              label: 'Hashing Algo',
              type: 'text',
              required: true,
              disabled: !!actionId,
              defaultValue: notifyFormData.hashingAlgorithm
            },
            {
              name: 'signatureHeader',
              label: 'Signature Header',
              type: 'text',
              required: true,
              disabled: !!actionId,
              defaultValue: notifyFormData.signatureHeader
            },
            isTransform || !!actionData?.transformation
              ? {
                  name: 'Transformation',
                  type: 'Fields',
                  duplicate: false,
                  varient: 'full',
                  data: {
                    0: [
                      {
                        name: 'ResponseObject-WebHooks',
                        label: 'Request Object',
                        type: 'dropdown',
                        options: responseObject,
                        defaultValue: JSON.stringify({
                          code: transformationFormData?.requestObject?.requestObjectCode,
                          dataObjectId: transformationFormData?.requestObject?.requestObjectId
                        }),
                        disabled: !!actionId
                      },
                      {
                        name: 'DataObject-WebHooks',
                        label: 'Data Object',
                        type: 'dropdown',
                        options: dataObject,
                        defaultValue: JSON.stringify({
                          code: transformationFormData?.dataObject?.dataObjectCode,
                          dataObjectId: transformationFormData?.dataObject?.dataObjectId
                        }),
                        disabled: !!actionId
                      },
                      {
                        name: 'persist-WebHooks',
                        label: 'Persist',
                        type: 'checkbox',
                        classname: '!mt-4',
                        defaultValue: !!transformationFormData?.persistRequired,
                        disabled: !!actionId
                      },
                      {
                        name: 'Check Mapping',
                        label: 'Check Mapping',
                        type: 'button',
                        classname: 'h-[40px] !mt-[46px]',
                        hideMe: !!actionId,
                        disabled: isNotifyDisabled,
                        onButtonClick: async () => {
                          const notifyInformationFields = notifyValidation[currentNotifyType];

                          const notifyInformationData = notifyInformationFields.reduce(
                            (acc, element) => {
                              return {
                                ...acc,
                                [element]: notifyRef.current[currentNotifyType][element] || ''
                              };
                            },
                            {}
                          );

                          const payload = {
                            dataObjectId: JSON.parse(
                              notifyInformationData[`DataObject-${currentNotifyType}`]
                            ).dataObjectId,
                            requestObjectId: JSON.parse(
                              notifyInformationData[`ResponseObject-${currentNotifyType}`]
                            ).dataObjectId,
                            appCode: app_code
                          };

                          try {
                            const response = await request
                              .post(
                                `${process.env.REACT_APP_CONFIG_API}/object-mapping/checkMapping`
                              )
                              .set('Authorization', `Bearer ${keycloak.token()}`)
                              .send(payload);

                            if (response.body.code == 'SUCCESS') {
                              setIsMappingChecked(true);
                              toast.custom(
                                (t) => <Success t={t} message={response.body.message} />,
                                {
                                  duration: 4000
                                }
                              );
                            } else {
                              toast.custom((t) => <Error t={t} message={response.body.message} />, {
                                duration: 4000
                              });
                            }
                          } catch (err) {
                            toast.custom(
                              (t) => (
                                <Error
                                  t={t}
                                  message={
                                    response.body.message || 'Error while checking connection'
                                  }
                                />
                              ),
                              {
                                duration: 4000
                              }
                            );
                          }
                        }
                      }
                    ]
                  }
                }
              : {},
            actionData?.ocr || isOCR
              ? {
                  name: 'Optical Character Recognition',
                  type: 'Fields',
                  duplicate: false,
                  varient: 'full',
                  data: {
                    0: [
                      {
                        name: 'oCRTypes-WebHooks',
                        label: 'OCR Types',
                        type: 'dropdown',
                        disabled: !!actionId,
                        defaultValue:
                          // notifyData[currentNotifyType].oCRTypes || {ocrId: actionData?.ocr?.ocrId, ocrName: } || '',
                          actionData?.ocr.ocrName,
                        // options: [
                        //   { name: 'INVOICE', value: 'invoice' },
                        //   {
                        //     name: 'PURCHASE ORDER',
                        //     value: 'purchaseOrder'
                        //   }
                        // ]
                        options: ocrIdOptions
                      }
                    ]
                  }
                }
              : {}
          ]
        };
        return newState;
      }
    }
  }

  const connectionFrameWorkSchema = connectionFrameworkBasicInfoSchema({
    partyIds,
    isPollingNeeded,
    basicInfoData,
    actionId
  });

  const [typeSchema, typeSchemaDispatch] = useReducer(
    typeSchemaReducer,
    connectionFrameworkTypeInfoSchema()
  );
  const [notifySchema, notifySchemaDispatch] = useReducer(
    NotifyReducer,
    connectionFrameworkNotifyInfoSchema()
  );

  useEffect(() => {
    setOCRData(null);
    isTestCheckReducer({ type: 'initialize' });
  }, [currentOcrTypeForm.ocrType]);

  useEffect(() => {
    typeSchemaDispatch({ type: `type/${currentConnectionType}` });

    return () => {
      isTestCheckReducer({ type: 'initialize' });
    };
  }, [
    currentConnectionType,
    secretKey,
    isSecretKeyCopied,
    isTypeDisabled,
    isTestQuery,
    JSON.stringify(currentMailTypeForm),
    JSON.stringify(currentDBTypeForm),
    JSON.stringify(currentSFTPTypeForm),
    JSON.stringify(currentWebHooksTypeForm),
    JSON.stringify(currentHttpTypeForm),
    JSON.stringify(currentOcrTypeForm),
    IMAPServer
  ]);

  useEffect(() => {
    setIsMappingChecked(false);
    if (actionId) {
      if (isKafka) {
        notifySchemaDispatch({ type: `notify/Kafka` });
      } else {
        notifySchemaDispatch({ type: `notify/WebHooks` });
      }
    } else {
      notifySchemaDispatch({ type: `notify/${currentNotifyType}` });
    }
  }, [
    JSON.stringify(notifyData[currentNotifyType]),
    currentNotifyType,
    JSON.stringify(responseObject),
    JSON.stringify(dataObject),
    isTransform,
    isNotifyDisabled,
    isOCR
  ]);

  useEffect(() => {
    if (isTransform || actionData?.transformation) {
      if (isEmpty(responseObject) && isEmpty(dataObject)) {
        request
          .get(
            `${process.env.REACT_APP_CONFIG_API}/data-objects/type?type=REQUEST_OBJECT&appCode=${app_code}`
          )
          .set('Authorization', `Bearer ${keycloak.token()}`)
          .then((data) => {
            const nameValue = data?.body?.dataObjects?.map((element) => {
              return {
                name: element.name,
                value: JSON.stringify({
                  code: element.code,
                  dataObjectId: element.dataObjectId
                })
              };
            });

            setResponseObject(nameValue);
          });

        request
          .get(
            `${process.env.REACT_APP_CONFIG_API}/data-objects/type?type=DATA_OBJECT&appCode=${app_code}`
          )
          .set('Authorization', `Bearer ${keycloak.token()}`)
          .then((data) => {
            const nameValue = data?.body?.dataObjects?.map((element) => {
              return {
                name: element.name,
                value: JSON.stringify({
                  code: element.code,
                  dataObjectId: element.dataObjectId
                })
              };
            });

            setDataObject(nameValue);
          });
      }
    }
  }, [isTransform, actionData?.transformation]);

  useEffect(() => {
    request
      .get(`${process.env.REACT_APP_CONFIG_API}/organization`)
      .set('Authorization', `Bearer ${keycloak.token()}`)
      .then((data) => {
        setPartyIds(data?.body?.organizationObjects);
      });
  }, []);

  const [notifyKeyCopyComponent, customContainerClass] = useMemo(
    function () {
      switch (currentConnectionType) {
        case 'webhook':
          return [
            <div className={`ml-4 flex ${actionId ? 'hidden' : 'block'}`}>
              {isSecretKeyCopied ? (
                <div className="bg-green-700 font-semibold p-2 text-white rounded-md mb-4 flex justify-center items-center">
                  Yeeh!! secret key copied
                  <img src={infoIcon} className="h-[30px] w-[30px] ml-4" />
                </div>
              ) : (
                <div className="bg-red-700 p-2 font-semibold text-white rounded-md mb-4 flex justify-center items-center">
                  Secret Key is single time Generated Key, Please do not forgot to copy
                  <img src={infoIcon} className="h-[30px] w-[30px] ml-4" />
                </div>
              )}
            </div>,
            'flex flex-col'
          ];

        case 'db': {
          if (currentDBTypeForm.type == 'mongo') {
            return [
              queryResult ? <MongoDBQueryResult codeString={queryResult} /> : null,
              queryResult ? 'flex flex-col-reverse' : ''
            ];
          } else if (currentDBTypeForm.type == 'mysql') {
            return [
              queryResult ? <DBQueryResult codeString={queryResult} /> : null,
              queryResult ? 'flex flex-col-reverse' : ''
            ];
          } else {
            return [null, ''];
          }
        }

        case 'ocr':
          return [
            OCRData ? <MongoDBQueryResult codeString={OCRData} /> : <h1>{OCRData}</h1>,
            OCRData ? 'flex flex-col-reverse' : 'iiii'
          ];

        default:
          return [null, ''];
      }
    },
    [queryResult, currentConnectionType, isSecretKeyCopied]
  );

  useEffect(() => {}, [OCRData]);

  const isBasicDisabled = useMemo(() => {
    const fieldCheck = ['name', 'orgId', 'type'];
    if (isPollingNeeded) {
      fieldCheck.push('polling');
    }
    return !fieldCheck.every((element) => {
      if (currentConnectionForm[element] == '') {
        return false;
      } else {
        return true;
      }
    });
  }, [JSON.stringify(currentConnectionForm), JSON.stringify(OCRData)]);

  const notify = ['Kafka', 'WebHooks'];

  const additionNotifyComponent = (
    <div className="min-w-[200px] mr-4 ml-4">
      <div className="flex max-w-[300px] items-center relative left-[-10px] mb-8">
        <Checkbox
          className="!relative !left-0"
          key={'transformation'}
          name={'transformation'}
          onChange={(e) => {
            setIsTransform(!isTransform);
          }}
          checked={isTransform || !!actionData?.transformation}
          disabled={!!actionId}
        />
        <div className="font-semibold">Transformation</div>
      </div>
      {currentConnectionType == 'Mail' || currentConnectionType == 'SFTP' ? (
        <div className="flex max-w-[300px] items-center relative left-[-10px] mt-8">
          <Checkbox
            className="!relative !left-0"
            key={'OCR'}
            name={'OCR'}
            onChange={(e) => {
              setIsOCR(!isOCR);
            }}
            checked={isOCR || !!actionData?.ocr}
            disabled={!!actionId}
          />
          <div className="font-semibold">Optical Character Recognition</div>
        </div>
      ) : null}
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={!!actionId ? (isKafka ? 'Kafka' : 'WebHooks') : 'Kafka'}
          name="radio-buttons-group">
          {notify.map((element) => {
            return (
              <FormControlLabel
                onClick={() => setCurrentNotifyType(element)}
                value={element}
                control={<Radio />}
                label={element}
                disabled={!!actionId}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );

  const onFormSubmit = async () => {
    const basicInfoFields = ['name', 'orgId', 'type', 'description'];
    if (isPollingNeeded) {
      basicInfoFields.push('polling');
    }
    const basicInfoData = basicInfoFields.reduce((acc, element) => {
      return {
        ...acc,
        [element]: currentConnectionForm[element] || ''
      };
    }, {});

    const updatedTypeValidation = typeFieldValidation[currentConnectionType];

    if (currentConnectionType == 'db' && currentDBTypeForm.type == 'mongo') {
      updatedTypeValidation.push('collection');
    }

    const typeInformationData = updatedTypeValidation.reduce((acc, element) => {
      return {
        ...acc,
        [element]: typeData[currentConnectionType][element] || ''
      };
    }, {});

    if (currentConnectionType == 'ocr') {
      typeInformationData['jsonFormat'] = OCRData;
      typeInformationData['ocrFilePath'] = FileData;
      console.log('Data is .....', typeInformationData, FileData);
    }

    const notifyInformationFields = notifyValidation[currentNotifyType].concat([
      `persist-${currentNotifyType}`
    ]);

    if (isOCR) {
      notifyValidation[currentNotifyType].concat([`Optical Character Recognition-0`]);
    }

    const notifyInformationData = notifyInformationFields.reduce((acc, element) => {
      return {
        ...acc,
        [element]: notifyData[currentNotifyType][element] || ''
      };
    }, {});

    const updatedNotifyData = Object.keys(notifyInformationData).reduce((acc, element) => {
      if (
        element in
        {
          [`DataObject-${currentNotifyType}`]: true,
          [`ResponseObject-${currentNotifyType}`]: true,
          [`persist-${currentNotifyType}`]: true,
          [`oCRTypes-${currentNotifyType}`]: true,
          persist: true
        }
      ) {
        return acc;
      } else {
        return {
          ...acc,
          [element]: notifyInformationData[element]
        };
      }
    }, {});

    console.log("Notify Data..................",notifyInformationData);

    const tempTypeMap = {
      mail: 'email',
      db: 'db',
      sftp: 'sftp',
      webhook: 'webhook',
      http: 'http',
      ocr: 'ocr'
    };

    const tempNotifyMap = {
      webhooks: 'webhook',
      kafka: 'kafka'
    };

    const payload = {
      ...basicInfoData,
      type: tempTypeMap[currentConnectionType.toLocaleLowerCase()],
      isActive: 'ACTIVE',
      appCode: app_code,
      metadata: {
        ...typeInformationData
      },
      notifyData: {
        ...updatedNotifyData,
        type: tempNotifyMap[currentNotifyType.toLocaleLowerCase()]
      },
      ...(isTransform
        ? {
            transformation: {
              transformationRequired: true,
              persistRequired: !!notifyInformationData[`persist-${currentNotifyType}`],
              requestObject: {
                requestObjectId: JSON.parse(
                  notifyInformationData[`ResponseObject-${currentNotifyType}`]
                ).dataObjectId,
                requestObjectCode: JSON.parse(
                  notifyInformationData[`ResponseObject-${currentNotifyType}`]
                ).code
              },
              dataObject: {
                dataObjectId: JSON.parse(notifyInformationData[`DataObject-${currentNotifyType}`])
                  .dataObjectId,
                dataObjectCode: JSON.parse(notifyInformationData[`DataObject-${currentNotifyType}`])
                  .code
              }
            }
          }
        : {}),
      ...(isOCR
        ? {
            ocr: {
              ocrRequired: true,
               ...(JSON.parse(notifyInformationData[`oCRTypes-${currentNotifyType}`]))
              // ocrName:notifyInformationData[`oCRTypes-${currentNotifyType}`]
            }
          }
        : {})
    };

    // console.log(payload, notifyInformationData, '🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀');

    const result = await request
      .post(`${process.env.REACT_APP_CONFIG_API}/type/connectors/connections/create`)
      .send(payload)
      .set('Authorization', `Bearer ${keycloak.token()}`);

    if (result.body.Status == 'Success') {
      console.log("Notify Data..................",notifyInformationData);
      toast.custom((t) => <Success t={t} message={'Connection Form Submitted Successfully'} />, {
        duration: 4000
      });
      setWebhookId(result?.body?.CreatedId?.webhookId);
      if (currentConnectionType == 'webhook' && result?.body?.CreatedId?.webhookId) {
        setWebhookId(result?.body?.CreatedId?.webhookId);

        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.webhookId,
            isOpen: true
          })
        );
      } else if (currentConnectionType == 'ocr' && result?.body?.CreatedId?.ocrId) {
        setWebhookId(result?.body?.CreatedId?.ocrId);
        setWebHookurl(`${process.env.REACT_APP_CONFIG_API}/type/connectors/ocr`);
        dispatch(
          genericModalStateUpdate({
            modalKey: ModalKeys.webhookId,
            isOpen: true
          })
        );
      } else {
        navigate(`/apps/${app_code}/connections`);
      }
    } else {
      toast.custom((t) => <Error t={t} message={result.body.message} />, {
        duration: 4000
      });
    }
  };

  const copyWebHookUrl = async () => {
    await navigator.clipboard.writeText(`${webhookUrl}/${webhookId}`);
    navigate(`/apps/${app_code}/connections`);
  };

  const cancelButtonClick = () => {
    navigate(`/apps/${app_code}/connections`);
  };

  const formComponent = (
    <div className="mt-[52px] flex-1 px-[100px]">
      <div class="text-[32px] font-semibold mb-4">
        {!!actionId ? 'View Connection' : 'New Connection'}
      </div>
      <LadderStepForm
        id="connectionFramework"
        elements={[
          {
            title: 'Basic Information',
            child: (
              <FormV2Container
                className="flex w-[100%] flex-wrap"
                schema={connectionFrameWorkSchema}
                onSubmitForm={noop}
                disableSubmit={true}
              />
            ),
            isNextDisabled: isBasicDisabled,
            nextText: !!actionId ? 'View Connection Profile' : 'Add Connection Profile'
          },
          {
            title: 'Connection Profile',
            child: (
              <FormV2Container
                className="flex w-[100%] flex-wrap"
                schema={typeSchema}
                onSubmitForm={noop}
                disableSubmit={true}
              />
            ),
            isNextDisabled: actionId
              ? false
              : isTypeDisabled || !isTestCheck[currentConnectionType],
            nextText: !!actionId ? 'View Transformation & Notify' : 'Add Transformation & Notify',
            additionalComponent: notifyKeyCopyComponent,
            additionalComponentClass: customContainerClass
          },
          {
            title: 'Transformation & Notify Configuration',
            child: (
              <FormV2Container
                className="flex w-[100%] flex-wrap"
                schema={notifySchema}
                onSubmitForm={noop}
                disableSubmit={true}
              />
            ),
            isNextDisabled: isNotifyDisabled || (isTransform ? !isMappingChecked : false),
            nextText: 'Submit Connections',
            submitForm: onFormSubmit,
            additionalComponent: additionNotifyComponent,
            additionalComponentClass: 'flex',
            noNext: !!actionId
          }
        ]}
      />
    </div>
  );

  return (
    <div className="flex bg-[#F4F3FA]">
      <SideBar
        page={'connectionFramework'}
        onMenuItemClick={onMenuItemClick}
        currentContext={{ connectionFramework: 'New Connection' }}
      />
      {actionId ? (actionData ? formComponent : null) : formComponent}
      <ConformationContainer
        type="submit"
        modalId={ModalKeys.webhookId}
        cancelObject={{ text: 'Cancel', callback: cancelButtonClick }}
        conformObject={{
          text: 'Copy URL',
          callback: copyWebHookUrl
        }}
        modalText={{
          header: 'URL',
          desc: `${webhookUrl}/${webhookId} Please Copy url to send you data on the endpoint`
        }}
      />
      <GenericModalContainer modalKey={ModalKeys.ocrFileUplaod}>
        <OcrFileDrop
          setOCRData={setOCRData}
          setFileData={setFileData}
          isTestCheckReducer={isTestCheckReducer}
          ocrType={currentOcrTypeForm.ocrType}
        />
      </GenericModalContainer>
    </div>
  );
};

export default ConnectionFrameworkForm;
