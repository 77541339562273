import { noop } from 'lodash';
import SideBar from '../../ApplicationV2/component/SideBar';
import TraceComponent from '../components/trace.component';
import { useState } from 'react';
import DurationComponent from '../components/duration.component';
import SearchCriteria from '../components/searchCriteria.component';
import TopSubHeader from '../../../layouts/TopSubheader';
import getExtensionSubheaderSchema from '../../../utils/navigationUtils/getExtensionSubheaderSchema';

const TraceContainer = () => {
  const [currentContext, setCurrentContext] = useState('Analysis');

  const onMenuClick = ({ title }) => {
    switch (title) {
      case 'Trace': {
        setCurrentContext(title);
        break;
      }
      case 'Analysis': {
        setCurrentContext(title);
        break;
      }
      case 'Search Criteria': {
        setCurrentContext(title);
        break;
      }
    }
  };

  switch (currentContext) {
    case 'Trace': {
      return (
        <div className="flex bg-[#F4F3FA]">
          <SideBar
            page={'trace'}
            onMenuItemClick={onMenuClick}
            currentContext={{ trace: 'Trace' }}
          />
          <TopSubHeader subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}>
            <TraceComponent />
          </TopSubHeader>
        </div>
      );
    }

    case 'Analysis': {
      return (
        <div className="flex bg-[#F4F3FA]">
          <SideBar
            page={'trace'}
            onMenuItemClick={onMenuClick}
            currentContext={{ trace: 'Analysis' }}
          />
          <div className="flex-1 flex flex-col">
            <TopSubHeader subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}>
              <DurationComponent />
            </TopSubHeader>
          </div>
        </div>
      );
    }

    case 'Search Criteria': {
      return (
        <div className="flex bg-[#F4F3FA]">
          <SideBar
            page={'trace'}
            onMenuItemClick={onMenuClick}
            currentContext={{ trace: 'Search Criteria' }}
          />
          <div className="flex-1 flex flex-col">
            <TopSubHeader subheaderNavigationSchema={getExtensionSubheaderSchema({ extension: true })}>
              <SearchCriteria />
            </TopSubHeader>
          </div>
        </div>
      );
    }
  }
};

export default TraceContainer;
