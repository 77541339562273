import { noop } from 'lodash';
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReactJson from 'react-json-view';

const SourceObjectModal = ({ sourceObjectData = [], onCloseModal = noop, context = '' }) => {

  if (!sourceObjectData) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto">
      <div className="relative z-50 bg-white w-[800px] shadow-lg rounded-lg border-4 border-blue-500">
        <div className="flex justify-between border-b-4 p-4">
          <div>
            <h1 className='text-3xl font-bold ml-4'>{context == 'transformedObject'? 'TransFormed Object': 'Source Object'}</h1>
          </div>
          <div>
            <button onClick={onCloseModal}>
              <HighlightOffIcon className="!text-3xl !w-8 !h-8" />
            </button>
          </div>
        </div>
        <div className='p-6'>
          <ReactJson src={sourceObjectData} style={{ fontSize: '18px' }} />
        </div>
      </div>
    </div>
  );
};

export default SourceObjectModal;
